import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    dark: {
      backgroundColor: '#f4f4f8',
      color: '#4a4e4d',
      borderRadius: 5,
      padding: 10,
      margin: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.dark}>
      <Typography variant='subtitle1'>Third Party Services</Typography>

      <ol>
        <li>
          <Typography variant='body1'>
            Youtube
          </Typography>

          <Typography variant='body1'>
            <ul>
              <li>Radio Kasoot uses YouTube API Services to provide some of Radio Kasoot content.</li>
              <li>Youtube is a popular online video sharing and social media platform owned by Google. When using any Radio Kasoot services on any platform, users are bound by the YouTube Terms of Service You can find the YouTube Terms of Service here: <a href='https://www.youtube.com/t/terms'>https://www.youtube.com/t/terms</a> and the Google Privacy Policy here: <a href='http://www.google.com/policies/privacy'>http://www.google.com/policies/privacy</a></li>
            </ul>
          </Typography>
        </li>
      </ol>
    </div>
  );
};
