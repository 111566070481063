import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    dark: {
      backgroundColor: '#f4f4f8',
      color: '#4a4e4d',
      borderRadius: 5,
      padding: 10,
      margin: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.dark}>
      <Typography variant='subtitle1'>Other information</Typography>

      <ol>
        <li>
          <Typography variant='body1'>
            We may also collect or ask you to provide other information that
            cannot be used to identify you, such as date or year of birth, age,
            gender, geographic location, computer capabilities, preferences, IP
            addresses, browser type and language, access times and referring web
            site addresses, and information about your visit and use of the
            Service, such as the pages you view, the links you click and session
            data.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            To do this, we may use cookies and other technology (see “Cookies”
            below for more detail about the use of such technologies) and we may
            acquire such non-personal information from third party online
            tracking service providers.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            We may combine non-personal information with personal information.
          </Typography>
        </li>
      </ol>
    </div>
  );
};
