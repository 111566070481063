import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import logo from '../../images/logo.svg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      flexGrow: 1,
    },
    flex: {
      flex: 1,
    },
    logo: {
      height: 50,
    },
    appbar: {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      maxWidth: 800,
      margin: '0 auto',
    },
    button: {
      fontSize: 18,
      fontWeight: 500,
      color: '#2ac9a7',
    },
    link: {
      textDecoration: 'none',
    },
  })
);

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position='sticky' className={classes.appbar}>
        <Toolbar>
          <img src={logo} alt='Radio Kasoot' className={classes.logo} />

          <div className={classes.flex}></div>

          <a href='/' className={classes.link}>
            <Button className={classes.button} size='small' color='inherit'>
              होम
            </Button>
          </a>
        </Toolbar>
      </AppBar>
    </div>
  );
};
