import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import Footer from '../common/Footer';
import About from './About';
import App from './App';
import Navbar from './Navbar';
import Radio from './Radio';
import Services from './Services';
import Spacer from './Spacer';

export default () => (
  <React.Fragment>
    <CssBaseline />
    <Navbar />
    <Radio />
    <About />
    <Services />
    <App />
    {/* <Team /> */}
    {/* <Partners /> */}
    <Spacer />
    <Footer />
  </React.Fragment>
);
