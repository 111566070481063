import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// material ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// assets
import YoutubeIcon from "../../images/yt.svg";
import FacebookIcon from "../../images/facebook.svg";
import TwitterIcon from "../../images/twitter.svg";
import InstagramIcon from "../../images/instagram.svg";
import EmailIcon from "../../images/email.svg";
import LogoKesari from "../../images/logo-kesari.svg";
import LogoText from "../../images/logo-text.svg";

class Footer extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} id="contact">
        <div className={classes.section}>
          <Typography className={classNames(classes.dark, classes.title)}>
            <strong>Contact us</strong>
          </Typography>

          <Grid container spacing={24} justify="flex-center">
            <Grid item xs={6} sm={4} md={4} className={classes.grid}>
              <div>
                <div className={classes.linkContainer}>
                  <img
                    src={YoutubeIcon}
                    className={classes.iconDetails}
                    alt="Radio Kasoot"
                  />
                </div>
                <Typography className={classNames(classes.text, classes.dark)}>
                  <a
                    className={classes.link}
                    href="https://youtube.com/radiokasoot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    youtube
                  </a>
                </Typography>
              </div>

              <div>
                <div className={classes.linkContainer}>
                  <img
                    src={FacebookIcon}
                    className={classes.iconDetails}
                    alt="Radio Kasoot"
                  />
                </div>
                <Typography className={classNames(classes.text, classes.dark)}>
                  <a
                    className={classes.link}
                    href="https://facebook.com/radiokasoot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    facebook
                  </a>
                </Typography>
              </div>

              <div>
                <div className={classes.linkContainer}>
                  <img
                    src={TwitterIcon}
                    className={classes.iconDetails}
                    alt="Radio Kasoot"
                  />
                </div>
                <Typography className={classNames(classes.text, classes.dark)}>
                  <a
                    className={classes.link}
                    href="https://twitter.com/radiokasoot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    twitter
                  </a>
                </Typography>
              </div>
            </Grid>

            <Grid item xs={6} sm={4} md={4} className={classes.grid}>
              <div>
                <div className={classes.linkContainer}>
                  <img
                    src={InstagramIcon}
                    className={classes.iconDetails}
                    alt="Radio Kasoot"
                  />
                </div>
                <Typography className={classNames(classes.text, classes.dark)}>
                  <a
                    className={classes.link}
                    href="https://instagram.com/radiokasoot"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    instagram
                  </a>
                </Typography>
              </div>

              <div>
                <div className={classes.linkContainer}>
                  <img
                    src={EmailIcon}
                    className={classes.iconDetails}
                    alt="Radio Kasoot"
                  />
                </div>
                <Typography className={classNames(classes.text, classes.dark)}>
                  <a
                    className={classes.link}
                    href="mailto:info@radiokasoot.com"
                  >
                    Email
                  </a>
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={4} className={classes.grid}>
              <div className={classes.logoContainer}>
                <img
                  src={LogoKesari}
                  alt="Radio Kasoot Logo"
                  className={classes.logo}
                />
                <div></div>
                <img
                  src={LogoText}
                  alt="Radio Kasoot Logo"
                  className={classes.logo}
                />
              </div>

              <Typography variant="caption">
                &copy; {new Date().getFullYear()} Radio Kasoot
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  root: {
    background: "#f4f4f8",
    flexGrow: 1,
    padding: 25,
  },
  section: {
    maxWidth: 800,
    margin: "0 auto",
    textAlign: "center",
  },
  title: {
    fontSize: 24,
  },
  dark: {
    color: "#4a4e4d",
  },
  grid: {
    textAlign: "left",
  },
  linkContainer: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: 5,
  },
  iconDetails: {
    height: 38,
    width: 38,
  },
  text: {
    display: "inline-block",
    verticalAlign: "middle",
    fontWeight: 500,
    marginLeft: 5,
  },
  link: {
    textDecoration: "none",
    color: "#4a4e4d",
  },
  logoContainer: {
    textAlign: "center",
  },
  logo: {
    height: 50,
  },
};

export default withStyles(styles)(Footer);
