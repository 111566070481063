import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';
import AppStore from '../../images/app-store.svg';
import PlayStore from '../../images/play-store.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#f4f4f8',
      flexGrow: 1,
      padding: '25px 0',
    },
    section: {
      maxWidth: 800,
      margin: '0 auto',
      textAlign: 'center',
    },
    title: {
      fontSize: 24,
    },
    dark: {
      color: '#4a4e4d',
      padding: 20,
    },
    store: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    storeImage: {
      height: '5vw',
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id='app'>
      <div className={classes.section}>
        <Typography className={classNames(classes.dark, classes.title)}>
          <strong>
            पहला तो रेडियो कसूत की एप्लीकेशन डाउनलोड करो ! फेर करांगे बाकी बात !
            कती फ्री मै है !
          </strong>
        </Typography>

        <div className={classes.store}>
          <div></div>
          <div></div>
          <a
            href='https://play.google.com/store/apps/details?id=com.radiokasoot.www'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={PlayStore}
              alt='PlayStore'
              className={classes.storeImage}
            />
          </a>
          <a
            href='https://itunes.apple.com/us/app/id1086344819'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={AppStore} alt='AppStore' className={classes.storeImage} />
          </a>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
