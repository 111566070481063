import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: 50,
    },
  })
);

export default () => {
  const classes = useStyles();

  return <div className={classes.root}></div>;
};
