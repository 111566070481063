import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    dark: {
      backgroundColor: '#f4f4f8',
      color: '#4a4e4d',
      borderRadius: 5,
      padding: 10,
      margin: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.dark}>
      <Typography variant='subtitle1'>Use of information (Purpose)</Typography>

      <ol>
        <li>
          <Typography variant='body1'>
            When we collect personal information from you, we will advise you of
            the purpose for which we are collecting that information.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            The personal information you provide will allow us to complete your
            registration; provide you access to Radio Kasoot websites and/or
            applications and your account; to add or amend information which is
            listed in your account; send you relevant industry bulletins; alert
            you of new features or enhancements; handle/route your customer
            service questions or issues; notify you of upgrade opportunities and
            offers; notify you of other services that we or our related entities
            provide which are similar or related to Radio Kasoot websites and/or
            applications; notify you of complaints made in relation to content
            that you have posted under our Copyright Policy; and/or give you
            updates about changes to Radio Kasoot websites and/or applications,
            services or your account.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            We may use your personal information for internal marketing,
            profiling, or demographic purposes within our corporate group, so we
            can adapt our services and develop new services to better suit your
            needs.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            Except where otherwise allowed by law, we will not use your personal
            information for any purpose other than the purpose for which it was
            collected, or a directly related purpose. We will not change the way
            we use your personal information unless you have given us permission
            to do so.
          </Typography>
        </li>
      </ol>
    </div>
  );
};
