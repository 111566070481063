import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Personal from './Personal';
import Other from './Other';
import Purpose from './Purpose';
import Disclosure from './Disclosure';
import Security from './Security';
import ThirdParty from './ThirdParty';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: 'white',
      flexGrow: 1,
      padding: '25px 0',
    },
    section: {
      maxWidth: 800,
      margin: '0 auto',
    },
    dark: {
      color: '#4a4e4d',
      padding: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <Typography variant='subtitle1'>
          Terms of Service and Privacy Policy
        </Typography>

        <Personal />
        <Other />
        <Purpose />
        <Disclosure />
        <Security />
        <ThirdParty />
      </div>
    </div>
  );
};
