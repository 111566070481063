import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    dark: {
      backgroundColor: '#f4f4f8',
      color: '#4a4e4d',
      borderRadius: 5,
      padding: 10,
      margin: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.dark}>
      <Typography variant='subtitle1'>Security</Typography>

      <ol>
        <li>
          <Typography variant='body1'>
            We are committed to ensuring the security of your personal
            information. We will take all reasonable steps to protect your
            personal information against loss, misuse and unauthorised access,
            modification or disclosure. However, we cannot guarantee the
            security of information transmitted over the Internet, or of
            personal information which is displayed on your account.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            We recommend that you take all necessary precautions to protect your
            personal information while you are online.
          </Typography>
        </li>
      </ol>
    </div>
  );
};
