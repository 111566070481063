import React from 'react';

// material ui
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    dark: {
      backgroundColor: '#f4f4f8',
      color: '#4a4e4d',
      borderRadius: 5,
      padding: 10,
      margin: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.dark}>
      <Typography variant='subtitle1'>Disclosure</Typography>

      <ol>
        <li>
          <Typography variant='body1'>
            We will not disclose your personal information to any person without
            your consent, except as outlined in this clause.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            We may disclose your personal information to third parties where we
            are authorised or required by law to do so, and in order to take
            action with respect to any suspected breach of the Terms of Service.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            We may disclose your personal information to our employees and third
            parties who have been engaged to perform services for us relating to
            the functioning of Radio Kasoot websites and/or applications. Any
            third party engaged by us is required to comply with the same
            obligations in relation to your personal information as we are under
            the Terms of Service.
          </Typography>
        </li>
      </ol>
    </div>
  );
};
