import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import logo from '../../images/logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      flexGrow: 1,
    },
    flex: {
      flex: 1,
    },
    logo: {
      height: 50,
    },
    appbar: {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
      maxWidth: 800,
      margin: '0 auto',
    },
    button: {
      fontSize: 18,
      fontWeight: 500,
      color: '#2ac9a7',
    },
    link: {
      textDecoration: 'none',
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position='sticky' className={classes.appbar}>
        <Toolbar>
          <img src={logo} alt='Radio Kasoot' className={classes.logo} />

          <div className={classes.flex}></div>

          <Hidden smDown>
            <Button
              className={classes.button}
              size='small'
              href='#about'
              color='inherit'
            >
              म्हारे बारे में
            </Button>

            <Button
              className={classes.button}
              size='small'
              href='#services'
              color='inherit'
            >
              सेवाएं
            </Button>

            <Button
              className={classes.button}
              size='small'
              href='#app'
              color='inherit'
            >
              एप्प
            </Button>

            {/* <Button
              className={classes.button}
              size='small'
              href='#team'
              color='inherit'
            >
              टीम
            </Button>

            <Button
              className={classes.button}
              size='small'
              href='#partners'
              color='inherit'
            >
              सहयोगी
            </Button> */}

            <Button
              className={classes.button}
              size='small'
              href='#contact'
              color='inherit'
            >
              संपर्क
            </Button>
          </Hidden>

          <a href='/privacy' className={classes.link}>
            <Button className={classes.button} size='small' color='inherit'>
              गोपनीयता
            </Button>
          </a>
        </Toolbar>
      </AppBar>
    </div>
  );
};
