import { createStyles, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

const thisWindow: any = window;

const mobileOperatingSystem = () => {
  let userAgent = navigator.userAgent || navigator.vendor || thisWindow.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

const goToYoutube = () => {
  setTimeout(() => {
    switch (mobileOperatingSystem()) {
      case 'Android':
        window.location.href =
          'intent://youtube.com/RadioKasoot#Intent;package=com.google.android.youtube;scheme=http;end;';
        break;
      default:
        window.location.href =
          'https://www.youtube.com/RadioKasoot?sub_confirmation=1';
        break;
    }
  }, 3000);
};

export default () => {
  const classes = useStyle();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CircularProgress size={100} />
      </div>
      {goToYoutube()}
    </React.Fragment>
  );
};
