import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import Footer from '../common/Footer';
import Main from './Main';
import Navbar from './Navbar';

export default () => (
  <React.Fragment>
    <CssBaseline />
    <Navbar />
    <Main />
    <Footer />
  </React.Fragment>
);
