import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';
import background from '../../images/background.png';
import logo from '../../images/logo-with-text.svg';

const Streams = [
  {
    label: 'Live Radio',
    url: 'https://azuracast.radiokasoot.com/radio/8000/listen',
  },
  {
    label: 'Raagni',
    url: 'https://azuracast.radiokasoot.com/radio/8010/raagni',
  },
  { label: 'Gaane', url: 'https://azuracast.radiokasoot.com/radio/8020/songs' },
];

const styles = createStyles({
  root: {
    background: `center / cover no-repeat url(${background})`,
    flexGrow: 1,
    padding: '25px 0',
  },
  title: {
    fontSize: 24,
  },
  logo: {
    height: 100,
  },
  section: {
    maxWidth: 800,
    margin: '0 auto',
    textAlign: 'center',
  },
  white: {
    color: 'white',
    padding: 20,
  },
  tagline: {
    fontSize: 16,
    fontWeight: 500,
  },
  padding: {
    padding: 10,
  },
  button: {
    backgroundColor: '#ff7633',
    color: 'white',
    borderRadius: 20,
  },
  formControl: {
    minWidth: 120,
    background: '#fff',
  },
});

class Radio extends React.Component<any, any> {
  state = {
    currentStream: Streams[0],
  };

  handleStreamChange = (event: any) => {
    const label = event.target.value;
    this.setState({
      currentStream: Streams.find((stream) => stream.label === label),
    });
  };

  render() {
    const { classes } = this.props;
    const { currentStream } = this.state;

    return (
      <div className={classes.root} id='radio'>
        <div className={classes.section}>
          <img src={logo} alt='Radio Kasoot' className={classes.logo} />

          <Typography className={classNames(classes.white, classes.title)}>
            <strong>अपणी बोली अपणे गीत</strong>
          </Typography>

          <Typography className={classNames(classes.white, classes.tagline)}>
            रेडियो कसूत हरियाणवी सभ्यता, हरियाणवी संगीत, हरियाणवी बोली के साथ
            साथ हरियाणवी कला, इंटरव्यूज, सामान्य ज्ञान और संस्कृति का मंच है जो
            इन सबनै एक साथ जोडकै सबनै एक साथ लेकै चाल सकै है ! सम्भावना बहोत घणी
            है ! एक बै सुण कै तो देखो !
          </Typography>

          <FormControl className={classes.formControl}>
            <audio
              controls={true}
              id='live-radio'
              preload='metadata'
              title='Radio Kasoot, First Haryanvi Radio'
              src={this.state.currentStream.url}
            ></audio>

            <Select
              value={currentStream.label}
              name='stream'
              onChange={this.handleStreamChange}
            >
              {Streams.map((stream) => (
                <MenuItem key={stream.label} value={stream.label}>
                  {stream.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className={classes.padding}>
            <Button
              className={classes.button}
              variant='contained'
              href='#about'
            >
              और बेरा पाड़ ल्यो !
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Radio);
