import Button from '@material-ui/core/Button';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#f4f4f8',
      flexGrow: 1,
    },
    section: {
      maxWidth: 800,
      margin: '0 auto',
      textAlign: 'center',
    },
    title: {
      fontSize: 24,
    },
    dark: {
      color: '#4a4e4d',
      padding: 20,
    },
    tagline: {
      fontSize: 16,
      fontWeight: 500,
    },
    button: {
      backgroundColor: '#2ac9a7',
      color: 'white',
      borderRadius: 20,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id='about'>
      <div className={classes.section}>
        <Typography className={classNames(classes.dark, classes.title)}>
          <strong>हाम सा अपणी माटी की खूसबू, और कुछ नहीं!</strong>
        </Typography>

        <Typography className={classNames(classes.dark, classes.tagline)}>
          रेडियो कसूत पे अपणी माटी ते जुड़ा गीत संगीत हर टेम बाजता रवै है ! उसके
          साथ साथ में बहोत सारे सुथरे सुथरे प्रोग्राम थमने रेडियो कसूत की
          वेबसाइट पे सुणन नै मिले है जो जानकारी और मनोरंजन ते भरपूर है ! सारे
          प्रोग्राम हरयाणवी में ही प्रसारित करें जावे है !
        </Typography>

        <Button className={classes.button} variant='contained' href='#services'>
          सेवाएं
        </Button>
      </div>
    </div>
  );
};
