import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() =>
  createStyles({
    dark: {
      backgroundColor: '#f4f4f8',
      color: '#4a4e4d',
      borderRadius: 5,
      padding: 10,
      margin: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.dark}>
      <Typography variant='subtitle1'>Personal information</Typography>

      <ol>
        <li>
          <Typography variant='body1'>
            We may collect personal information from you where it is necessary
            for our functions and activities.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            “Personal information” means any information which can be used to
            identify you and includes information such as your name, address,
            email address and telephone number.
          </Typography>
        </li>
        <li>
          <Typography variant='body1'>
            You may not be able to create an account, access or use Radio Kasoot
            websites and/or applications unless you provide us with personal
            information.
          </Typography>
        </li>
      </ol>
    </div>
  );
};
