import Grid from '@material-ui/core/Grid';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import React from 'react';
import headphone from '../../images/headphone.svg';
import musicIcon from '../../images/music-icon.svg';
import onAirIcon from '../../images/on-air-icon.svg';
import towerIcon from '../../images/tower-icon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'white',
      flexGrow: 1,
      padding: '25px 0',
    },
    title: {
      fontSize: 24,
    },
    section: {
      maxWidth: 800,
      margin: '0 auto',
      textAlign: 'center',
    },
    dark: {
      color: '#4a4e4d',
      padding: 10,
    },
  })
);

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id='services'>
      <div className={classes.section}>
        <Typography className={classNames(classes.dark, classes.title)}>
          <strong>सारी हाण थारी सेवा में हाज़र - कती फ्री में</strong>
        </Typography>

        <Grid container spacing={8}>
          <Grid item xs={12} sm={6} md={3}>
            <img src={musicIcon} alt='music-icon' />
            <Typography variant='subtitle1' className={classes.dark}>
              <strong>हरियाणवी रागनी अर किस्से</strong>
            </Typography>
            <Typography variant='caption' className={classes.dark}>
              किस्से और रागनियाँ का सुवाद रेडियो कसूत ते घणा कित आ सकै है !
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <img src={headphone} alt='headphone' />
            <Typography variant='subtitle1' className={classes.dark}>
              <strong>हरियाणवी गीत अर भजन</strong>
            </Typography>
            <Typography variant='caption' className={classes.dark}>
              नए पुराणे गीत अर भजन का तो कसूता तोड़ बिठा राखा सै रेडियो कसूत नै !
              सुनो तो बेरा लागै !
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <img src={towerIcon} alt='tower-icon' />
            <Typography variant='subtitle1' className={classes.dark}>
              <strong>24x7 लाइव</strong>
            </Typography>
            <Typography variant='caption' className={classes.dark}>
              सारी हाण थारे खातर रेडियो कसूत चलता रवे है ! बिना परेशानी बिना
              हैरानी ! मौज ल्यो रोज ल्यो !
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <img src={onAirIcon} alt='on-air-icon' />
            <Typography variant='subtitle1' className={classes.dark}>
              <strong>हरियाणवी कार्यक्रम</strong>
            </Typography>
            <Typography variant='caption' className={classes.dark}>
              हरियाणवी सभ्यता, संगीत, बोली, कला, इंटरव्यूज, सामान्य ज्ञान,
              साहित्य और संस्कृति के कार्यक्रम सारी हाण चाले जावै है !
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
